import React, { useEffect, useRef, useState } from 'react'
import Panel from '../components/Dashboard/Panel'
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
import Sidebar from '../components/Dashboard/Sidebar'
import Releases from '../components/Dashboard/Releases'
import Payouts from '../components/Dashboard/Payouts'
import Labels from '../components/Dashboard/Labels'
import Artists from '../components/Dashboard/Artists'
import Claims from '../components/Dashboard/Claims'
import AddRelease from '../components/Dashboard/AddRelease'
import Analytics from '../components/Dashboard/Analytics'
import AddArtist from '../components/Dashboard/AddArtist'
import ReleaseDetails from '../components/Dashboard/ReleaseDetails'
import { auth, db } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import AddLabels from '../components/Dashboard/AddLabels'
import EditRelease from '../components/Dashboard/EditRelease'
import Profile from '../components/Dashboard/Profile'
import Splits from '../components/Dashboard/Splits'
import AddSplits from '../components/Dashboard/AddSplits'
import Reports from '../components/Dashboard/Reports'
import ReportsDetails from '../components/Dashboard/ReportsDetails'
import Members from '../components/Dashboard/Members'
import Teams from '../components/Dashboard/Teams'
import DailyTrends from '../components/Dashboard/DailyTrends'
import { child, get, ref } from 'firebase/database'
import maintenanceImg from '../assets/maintenance.png'
import notAllowedImg from '../assets/not-allowed.png'

const Dashboard = () => {

    // const navigate = useNavigate()

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //       if (user) {
    //         //
    //       } else {
    //         // User is signed out
    //         localStorage.removeItem('uid')
    //         navigate('/')
    //       }
    //     });
    // }, [])

    const mid = localStorage.getItem('mid')
    const uid = localStorage.getItem('uid')

    const messageBtn = useRef()

    const [accessAllowed, setAccessAllowed] = useState(null)
    const [maintenanceOn, setMaintenanceOn] = useState(null)

    const [trendsAllowed, setTrendsAllowed] = useState(false)

    const checkAccess = () => {
        get(child(ref(db, 'admin'), 'maintenance_mode'))
        .then(snapshot => {
            if (snapshot.exists()) {
                setMaintenanceOn(snapshot.val().status)
            } else {
                setMaintenanceOn(false)
            }
        })
        get(child(ref(db, 'users'), `${uid}`))
        .then(snapshot => {
            if (snapshot.exists()) {
                setAccessAllowed(snapshot.val().accessAllowed || false)
            }
        })
    }

    useEffect(() => {
        if (localStorage.getItem('aid') !== null) {
            get(child(ref(db, 'users'), localStorage.getItem('aid')))
            .then(snapshot => {
                if (snapshot.exists()) {
                    if (snapshot.val().role === 'admin' || snapshot.val().role === 'super-admin') {
                        setMaintenanceOn(false)
                        setAccessAllowed(true)
                    } else {
                        checkAccess()
                    }
                } else {
                    checkAccess()
                }
            })
        } else {
            checkAccess()
        }

        get(child(ref(db, 'users'), mid))
        .then(snapshot => {
            if (snapshot.exists()) setTrendsAllowed(snapshot.val().trendsAllowed === true ? true : false)
        })
        get(child(ref(db, 'users'), `${uid}`))
        .then(snapshot => {
            if (snapshot.exists()) {
                const releases = snapshot.val().releases || []
                const paymentSetup = snapshot.val().paymentSetup
                const foundLiveRelease = releases.find(release => release.status === 'Distributed')
                if (paymentSetup === false && foundLiveRelease) messageBtn.current.click()
            }
        })
    }, [])

  return (
    <div className='dashboard'>
        {
            accessAllowed === false && (
                <div className='maintenance-container'>
                    <img src={notAllowedImg} alt="" />
                    <h6>We'll let you in soon!</h6>
                </div>
            )
        }
        {
            accessAllowed === true && maintenanceOn === true && (
                <div className='maintenance-container'>
                    <img src={maintenanceImg} alt="" />
                    <h6>We'll be right back! <br /> Check back again tomorrow and we should have everything ready for you!</h6>
                </div>
            )
        }
        {
            accessAllowed === true && maintenanceOn === false && (
                <>
                    <div className="container">
                        <div className="d-flex flex-wrap">
                            <div className="dash-left-container px-2 py-2 dash-sidebar">
                                <Sidebar />
                            </div>
                            <div className="dash-right-container px-2 py-2">
                                {/* <DashboardHeader /> */}
                                <br />
                                <Routes>
                                    <Route element={<Panel />} path="panel" />
                                    <Route element={<Releases />} path="releases" />
                                    <Route element={<AddRelease />} path="releases/create" />
                                    <Route element={<ReleaseDetails />} path="releases/:id" />
                                    <Route element={<EditRelease />} path="releases/edit/:id" />
                                    <Route element={<Payouts />} path="payouts" />
                                    <Route element={<Labels />} path="labels" />
                                    <Route element={<AddLabels />} path="labels/create" />
                                    <Route element={<Artists />} path="artists" />
                                    <Route element={<AddArtist />} path="artists/create" />
                                    <Route element={<Claims />} path="claims" />
                                    <Route element={<Analytics />} path="analytics" />
                                    {trendsAllowed && <Route element={<DailyTrends />} path="dailyTrends" />}
                                    <Route element={<Reports />} path="reports" />
                                    <Route element={<ReportsDetails />} path="reports/:month" />
                                    <Route element={<Profile />} path="profile" />
                                    <Route element={<Splits />} path="splits" />
                                    <Route element={<Members />} path="members" />
                                    <Route element={<Teams />} path="teams" />
                                    <Route element={<AddSplits />} path="splits/:id" />
                                </Routes>
                            </div>
                        </div>
                    </div>

                    <button ref={messageBtn} data-bs-toggle="modal" data-bs-target="#messageModal" hidden></button>
                    <div className="modal fade" id="messageModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="messageModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body success-modal">
                                    <Link to={'#'} className='success-close-btn' data-bs-dismiss="modal">
                                        <i className="fi fi-rr-cross"></i>
                                    </Link>
                                    <i className="fi fi-rr-triangle-warning text-danger success-icon"></i>
                                    <h5>Please set up your payout account to receive payments</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    </div>
  )
}

export default Dashboard