import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import countries from '../../arrays/countries.json'
import languages from '../../arrays/languages.json'
// import genres from '../../arrays/genres.json'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, storage } from '../../firebase'
import { set, push, ref, get, child, update, remove } from 'firebase/database'
import { getDownloadURL, uploadBytes, ref as sRef, uploadBytesResumable } from 'firebase/storage'
import axios from 'axios'
import emails from '../../arrays/emails.json'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for styling
import { enGB } from 'date-fns/locale';


const AddRelease = () => {

    // const labels = [
    //     "Universal Music Group",
    //     "Sony Music Entertainment",
    //     "Warner Music Group",
    //     "EMI Music",
    //     "Capitol Records",
    //     "Interscope Records",
    //     "Columbia Records",
    //     "Atlantic Records",
    //     "RCA Records",
    //     "Def Jam Recordings",
    //     "Island Records",
    //     "Republic Records",
    //     "Sub Pop Records",
    //     "Motown Records",
    //     "Merge Records",
    //     "XL Recordings",
    //     "Domino Recording Company",
    //     "Nonesuch Records",
    //     "Jazzland Recordings",
    //     "Rough Trade Records"
    // ]
      

    const [tab, setTab] = useState(1)

    const [activeRadio, setActiveRadio] = useState(1);
    const [activeRadio1, setActiveRadio1] = useState(1);

    const [tracks, setTracks] = useState([])
    const [artists1, setArtists1] = useState([])
    const [artists2, setArtists2] = useState([])
    const [artistRole, setArtistRole] = useState('Featuring')
    const [artistName, setArtistName] = useState('Name')

    const [isrcDisabled, setIsrcDisabled] = useState(false)
    const [upcDisabled, setUpcDisabled] = useState(false)

    const [genres, setGenres] = useState([])
    const [genresList, setGenresList] = useState([])
    const [primaryGenresList, setPrimaryGenresList] = useState([])
    const [secondaryGenresList, setSecondaryGenresList] = useState([])
    const [genre, setGenre] = useState('')
    const [primaryGenre, setPrimaryGenre] = useState('')
    const [secondaryGenre, setSecondaryGenre] = useState('')

    const [editTitle, setEditTitle] = useState('')
    const [editId, setEditId] = useState('')
    const [editTrackIndex, setEditTrackIndex] = useState(0)

    const filterGenres = (e) => {
        setGenre(e.target.value)
        setGenresList(genres.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    const filterPrimaryGenres = (e) => {
        setPrimaryGenre(e.target.value)
        setPrimaryGenresList(genres.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    const filterSecondaryGenres = (e) => {
        setSecondaryGenre(e.target.value)
        setSecondaryGenresList(genres.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    const [labels, setLabels] = useState([])
    const [labelsList, setLabelsList] = useState(labels)
    const [label, setLabel] = useState('')

    const filterLabels = (e) => {
        setLabel(e.target.value)
        setLabelsList(labels.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    const removeArtist1 = (index) => {
        const updatedData = [...artists1];
        updatedData.splice(index, 1);
        setArtists1(updatedData);
    };
    const removeArtist2 = (index) => {
        const updatedData = [...artists2];
        updatedData.splice(index, 1);
        setArtists2(updatedData);
    };



    const formatDuration = (durationInSeconds) => {
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        const formattedDuration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        return formattedDuration;
    }



    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadText, setUploadText] = useState('Choose file...')
    const jsmediatags = window.jsmediatags

    const uploadTrack = async (file) => {
        const storageRef = sRef(storage, `tracks/${file.name}`);
      
        try {
          // Upload file to Firebase Storage with progress callback
          const uploadTask = uploadBytesResumable(storageRef, file);
          
          uploadTask.on('state_changed', (snapshot) => {
            // Calculate progress percentage and invoke the onProgress callback
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress)
          });
      
          await uploadTask;
      
          // Get download URL
          const downloadURL = await getDownloadURL(storageRef);
          return downloadURL;
      
        } catch (error) {
          console.error("Error uploading file:", error);
        }
    };
      

    const [loader, setLoader] = useState(false)

    const readFile = (event) => {
        // setLoader(true);
        setUploadText('Please wait...')
        const songs = event.target.files;
        const promises = [];
      
        for (let i = 0; i < songs.length; i++) {
          const song = songs[i];
          const promise = uploadTrack(song).then((result) => {
            return new Promise((resolve) => {
              setTimeout(() => {
                var uniqueId = Math.random().toString(36).substr(2, 9);
                const audio = new Audio(URL.createObjectURL(song));

      
                audio.onloadedmetadata = () => {
                  jsmediatags.read(song, {
                    onSuccess: function (tag) {
                      setTracks((oldTracks) => [
                        ...oldTracks,
                        {
                          title: tag.tags.title || song.name,
                          filename: song.name,
                          id: uniqueId,
                          artists: artists2,
                          genre: genre,
                          clipStart: clipStart,
                          isrc: isrc,
                          copyright: copyright,
                          trackLanguage: trackLanguage,
                          explicit: explicit,
                          lyrics: lyrics,
                          duration: formatDuration(audio.duration),
                          file: result,
                        },
                      ]);
                      resolve(); // Resolve the inner promise after handling the track
                    },
                    onError: function (error) {
                      console.log(error);
                      setTracks((oldTracks) => [
                        ...oldTracks,
                        {
                          title: song.name,
                          filename: song.name,
                          id: uniqueId,
                          artists: [],
                          genre: genre,
                          clipStart: clipStart,
                          isrc: isrc,
                          copyright: copyright,
                          trackLanguage: trackLanguage,
                          explicit: explicit,
                          lyrics: lyrics,
                          duration: formatDuration(audio.duration),
                          file: result,
                        },
                      ]);
                      resolve(); // Resolve the inner promise after handling the track
                    },
                  });
                };
              }, 500);
            });
          });
      
          promises.push(promise);
        }
      
        // Use Promise.all to wait for all promises to settle
        Promise.all(promises)
          .then(() => {
            // This block will be executed after all promises are settled
            // setLoader(false);
            setUploadText('Choose file...')
        })
        .catch((error) => {
            console.error('Error processing tracks:', error);
            setUploadText('Choose file...')
            // setLoader(false); // Ensure loader is set to false in case of an error
          });
    };
      

    const removeTrack = (index) => {
        const updatedData = [...tracks];
        updatedData.splice(index, 1);
        setTracks(updatedData);
    };


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
    
        const reorderedItems = Array.from(tracks);
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);
    
        setTracks(reorderedItems);
    };
    

    

    const [checkedCountries, setCheckedCountries] = useState(['Worldwide']);

    const handleCheckCountry = (country, e) => {
        if (e.target.checked) {
          setCheckedCountries([...checkedCountries, country]);
        } else {
          setCheckedCountries(checkedCountries.filter((c) => c !== country));
        }
    };


    const updateTrack = () => {

        const updatedTracks = tracks.map(item => {
          if (item.id === editId) {

            if (isrcDisabled) {
                return { 
                    ...item,
                    title: editTitle,
                    artists: artists2,
                    genre: genre,
                    clipStart: clipStart,
                    isrc: isrc,
                    copyright: copyright,
                    trackLanguage: trackLanguage,
                    explicit: explicit,
                    lyrics: lyrics,
                    autoISRC: true
                };
            } else {
                return { 
                    ...item,
                    title: editTitle,
                    artists: artists2,
                    genre: genre,
                    clipStart: clipStart,
                    isrc: isrc,
                    copyright: copyright,
                    trackLanguage: trackLanguage,
                    explicit: explicit,
                    lyrics: lyrics,
                    autoISRC: false
                };
            }
            
          }
          
          console.log(item)
          return item;
        });
    
        setIsrcDisabled(false)
        setTracks(updatedTracks);
    };




    //////////////////////////////////////////////
    ////////////////// BACKEND ///////////////////
    //////////////////////////////////////////////

    const [releases, setReleases] = useState([])
    const [platforms, setPlatforms] = useState([])
    const [drafts, setDrafts] = useState([])
    const [artists, setArtists] = useState([])
    const [username, setUsername] = useState('')

    const [adminsEmails, setAdminsEmails] = useState([])

    const [title, setTitle] = useState('')
    const [upc, setUpc] = useState('')
    const [originalDate, setOriginalDate] = useState(new Date())
    const [digitalDate, setDigitalDate] = useState(new Date())
    const [years, setYears] = useState([])
    const [copyrightYear, setCopyrightYear] = useState('')
    const [copyrightLine, setCopyrightLine] = useState('')
    const [publishingYear, setPublishingYear] = useState('')
    const [publishingLine, setPublishingLine] = useState('')
    const [clipStart, setClipStart] = useState('')
    const [isrc, setIsrc] = useState('')
    const [copyright, setCopyright] = useState('')
    const [trackLanguage, setTrackLanguage] = useState('Instrumental')
    // const [role, setRole] = useState('')
    // const [writerName, setWriterName] = useState('')
    // const [share, setShare] = useState('')
    const [distribution, setDistribution] = useState('Content ID Only')
    const [explicit, setExplicit] = useState('Yes')
    const [checkedAll, setCheckedAll] = useState(false)
    const [counter, setCounter] = useState('')
    const [prefix, setPrefix] = useState('')
    const [upcs, setUpcs] = useState([])
    const [lyrics, setLyrics] = useState('')
    const [newReleasesItems, setNewReleasesItems] = useState([])

    const successBtn = useRef()
    const warningPopupBtn = useRef()
    const uid = localStorage.getItem('uid')
    const savedRelease = localStorage.getItem('release')

    const initialValues = (item) => {
        setTitle(item.title)
        setArtists1(item.artists || [])
        setUpc(item.upc)
        setPrimaryGenre(item.primaryGenre)
        setSecondaryGenre(item.secondaryGenre)
        setLabel(item.label)
        setOriginalDate(new Date(item.originalDate))
        setDigitalDate(new Date(item.digitalDate))
        setCopyrightYear(item.copyrightYear)
        setCopyrightLine(item.copyrightLine)
        setPublishingYear(item.publishingYear)
        setPublishingLine(item.publishingLine)
        setCheckedCountries(item.countries)
        setTracks(item.tracks || [])
        // setWriterName(item.writerName)
        setDistribution(item.distribution)
        setImgSrc(item.artwork)
        item.artwork && setImgPreview(true)
        item.artwork && setUploadLabel('Replace file')
    }

    //////////// GET USER'S RELEASES ////////////
    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setUsername(`${snapshot.val().firstName} ${snapshot.val().lastName}`)
            if (snapshot.val().releases !== undefined) {
                setReleases(snapshot.val().releases); 
            } else {
                //
            }
            if (snapshot.val().artists !== undefined) {
                setArtists(snapshot.val().artists); 
            } else {
                //
            }
            if (snapshot.val().drafts !== undefined) {
                setDrafts(snapshot.val().drafts); 
            } else {
                //
            }
            if (snapshot.val().labels !== undefined) {
                setLabels(snapshot.val().labels); 
                setLabelsList(snapshot.val().labels); 
            } else {
                //
            }
            setPrefix(snapshot.val().customID || '')
            get(ref(db, 'platforms'))
            .then(snapshot => {
                if (snapshot.exists()) {
                    const platformsData = snapshot.val() || {}; // Get the data from the snapshot
                    const platformsArray = Object.entries(platformsData).map(([key, value]) => ({
                        id: key,
                        checked: false,
                        ...value
                    }));
                    setPlatforms(handleSort(platformsArray))
                }
            })

            get(ref(db, 'users'))
            .then(snapshot => {
                const usersData = snapshot.val() || {}; // Get the data from the snapshot
                const onlyAdmins = []
                Object.entries(usersData).forEach(([key, value]) => {
                    if (value.role === 'admin' || value.role === 'super-admin') {
                        onlyAdmins.push(value.email)
                    }
                })
                setAdminsEmails(onlyAdmins)
            })
        })
        get(child(ref(db, 'counter'), 'counter'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const currentYear = new Date().getFullYear() - 2000
                const counterYear = Math.floor(snapshot.val().number / 100000)
                if (currentYear !== counterYear) {
                    setCounter(currentYear * 100000 + 50000)
                } else {
                    setCounter(snapshot.val().number)
                }
            }
        })
        get(ref(db, 'upcs'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const upcData = snapshot.val() || {}; // Get the data from the snapshot
                const upcArray = Object.entries(upcData).map(([key, item]) => ({
                    ...item,
                    index: key
                }));
                setUpcs(upcArray);
            }
        })
        if (savedRelease !== null) {
            initialValues(JSON.parse(savedRelease))
        }
        get(ref(db, 'genres'))
        .then(snapshot => {
            if (snapshot.exists()) {
                const genresData = snapshot.val() || {}; // Get the data from the snapshot
                const genresArray = Object.entries(genresData).map(([key, item]) => (item.name));
                setGenres(genresArray);
                setGenresList(genresArray);
                setPrimaryGenresList(genresArray);
                setSecondaryGenresList(genresArray);
            }
        })

        fetchYear()
    }, [])

    const fetchYear = async () => {
        let year = 2025
        try {
            const response = await fetch("https://worldtimeapi.org/api/ip");
            if (!response.ok) {
                setCopyrightYear(2025);
                setPublishingYear(2025);
            } else {
                const data = await response.json();
                year = new Date(data.datetime).getFullYear();
                setCopyrightYear(year);
                setPublishingYear(year);
            }

            const yearsArray = []

            for (let currentYear = year; currentYear >= 1990; currentYear--) {
                yearsArray.push(currentYear);
            }

            setYears(yearsArray)
        } catch (err) {
          setError(err.message);
        }
    };

    const handleSort = (platformsArr) => {
        const sortedData = [...platformsArr].sort((a, b) => {
            return a.title.localeCompare(b.title);
        })

        return sortedData
    };


    const generateReleaseId = () => {
        let draftId = 0
        drafts.forEach(draft => {
            if (draft.id.includes('Draft#')) {
                const [drTitle, drId] = draft.id.split('#')
                draftId = Math.max(draftId, drId * 1)
            }
        })
        return `Draft #${draftId + 1}`
    }

    const draftSuccess = useRef()
    const [saveMsg, setSaveMsg] = useState('Save')
    const handleSave = () => {
        const release = {
            id: title.trim() === '' ? generateReleaseId() : title,
            title: title,
            artists: artists1,
            upc: upc,
            primaryGenre: primaryGenre,
            secondaryGenre: secondaryGenre,
            label: label,
            originalDate: originalDate,
            digitalDate: digitalDate,
            copyrightYear: copyrightYear,
            copyrightLine: copyrightLine,
            publishingYear: publishingYear,
            publishingLine: publishingLine,
            countries: checkedCountries,
            tracks: tracks,
            // share: share,
            // writerName: writerName,
            distribution: distribution,
            status: "Processing",
            artwork: imgSrc
        }
        if (savedRelease !== null) {
            const savedObject = JSON.parse(savedRelease)
            const draftIndex = drafts.findIndex(item => item.id === savedObject.id)
            const newDrafts = [...drafts]
            newDrafts[draftIndex] = release
            update(child(ref(db, 'users'), uid), {
                drafts: newDrafts
            })
        } else {
            const newDrafts = [release, ...drafts]
            update(child(ref(db, 'users'), uid), {
                drafts: newDrafts
            })
        }
        draftSuccess.current.click()
    }


    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 5000);
    }

    const hasArtistsForAllRoles = (artists, roles) => {
        const artistRoles = artists.map(artist => artist.role);

        let missingRoles = {}

        if (artistRoles.includes("Composer & Lyricist")) {
            roles = roles.filter(role => role !== "Composer" && role !== "Lyricist");
        }

        roles.forEach(role => {
            const foundRole = artistRoles.find(item => item === role)
            if (!foundRole) missingRoles[role] = true
        })

        // return roles.every(role => artistRoles.includes(role))
        return missingRoles
    };
      
    // Roles to check for
    const rolesToCheck = ['Composer', 'Lyricist', 'Primary Artist'];

    const handleCheck = (index) => {
        let newPlatforms = [...platforms]
        if (newPlatforms[index].checked === true) {
            newPlatforms[index].checked = false
            setPlatforms(newPlatforms)
        } else {
            newPlatforms[index].checked = true
            setPlatforms(newPlatforms)
        }
    }

    const checkAll = () => {
        setCheckedAll(!checkedAll)
        let newPlatforms = [...platforms]
        for (let i = 0; i < newPlatforms.length; i++) {
            const element = newPlatforms[i];
            element.checked = !checkedAll
        }
        setPlatforms(newPlatforms)
    }

    const updatePlatforms = () => {
        let newPlatforms = []
        let count = 0
        platforms.forEach(platform => {
            if (platform.checked === true) {
                newPlatforms.push({
                    id: platform.id,
                    status: 'Processing',
                    checked: true,
                    link: ''
                })
            }
            count++
        })
        if (count === platforms.length) {
            return newPlatforms
        }
    }


    const autoISRC = () => {
        const updatedTracks = []
        let count = 0
        for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];
            if (track.autoISRC === true) {
                updatedTracks.push({ 
                    ...track,
                    isrc: '',
                    autoISRC: true
                })
                count++
            } else {
                updatedTracks.push({ 
                    ...track,
                    autoISRC: false
                })
            }
        }

        return {updatedTracks, count}

    }


    const getNextNumber = (index) => {
        const nextNumber = prefix + String(index).padStart(5, '0');
        return nextNumber;
    }

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, hence +1)
        const year = date.getFullYear(); // Get year
      
        return `${year}/${month}/${day}`; // Construct "dd/mm/yyyy" format
    };

    
    const handleAddRelease = () => {

        const updatedTracks = autoISRC()
        const release = {
            customID: '',
            title: title,
            artists: artists1,
            upc: upcDisabled ? '' : upc,
            primaryGenre: primaryGenre,
            secondaryGenre: secondaryGenre,
            label: label,
            originalDate: formatDate(originalDate),
            digitalDate: formatDate(digitalDate),
            copyrightYear: copyrightYear,
            copyrightLine: copyrightLine,
            publishingYear: publishingYear,
            publishingLine: publishingLine,
            countries: checkedCountries,
            tracks: updatedTracks.updatedTracks,
            // share: share,
            // writerName: writerName,
            distribution: distribution,
            status: "Processing",
            artwork: imgSrc,
            platforms: updatePlatforms(),
            autoUPC: upcDisabled
        }
        
        // set(child(ref(db, 'releases'), releaseKey), release)
        let newReleases = [...releases, release]
        for(let i = 0; i < newReleases.length; i++) {
            newReleases[i].customID = getNextNumber(i + 1)
        }

        const hasAllRolesInReleaseArtists = hasArtistsForAllRoles(release.artists, rolesToCheck);
        const hasAllRolesInTracks = release.tracks.every(track => hasArtistsForAllRoles(track.artists || [], rolesToCheck));

        let missingArtistsMessage = []
        if (Object.keys(hasAllRolesInReleaseArtists).length !== 0) {
            const missingReleaseArtists = Object.keys(hasAllRolesInReleaseArtists).join(', ')
            missingArtistsMessage.push(`The release is missing the <i>${missingReleaseArtists}</i>`)
        }
        release.tracks.forEach((track, index) => {
            const missingArtists = hasArtistsForAllRoles(track.artists || [], rolesToCheck)
            if (Object.keys(missingArtists).length !== 0) {
                const missingTrackArtists = Object.keys(missingArtists).join(', ')
                missingArtistsMessage.push(`The track ${index + 1} is missing the <i>${missingTrackArtists}</i>`)
            }
        })
        if (missingArtistsMessage.length === 0) {
            const missingISRCs = tracks.find(item => item.isrc === '' && item.autoISRC === false)
            const missingTrackTitle = tracks.find(item => item.title.trim() === '')
            const missingTrackGenre = tracks.find(item => item.genre === '')
            if (imgPreview === false) {
                handleError('Artwork missing')
            } else if (title.trim() === '') {
                handleError('Title missing')
            } else if (tracks.length === 0) {
                handleError('Tracks missing')
            } else if (primaryGenre === '') {
                handleError('Missing primary genre')
            } else if (secondaryGenre === '') {
                handleError('Missing secondary genre')
            } else if (upc === '' && upcDisabled === false) {
                handleError('Missing UPC')
            } else if (missingISRCs) {
                handleError('Missing ISRCs')
            } else if (missingTrackTitle) {
                handleError('Missing track\'s title')
            } else if (missingTrackGenre) {
                handleError('Missing track\'s genre')
            } else {

                const primaryArtists = release.artists.filter(artist => artist.role === 'Primary Artist')

                if (primaryArtists.length >= 4) {
                    setNewReleasesItems(newReleases)
                    warningPopupBtn.current.click()
                } else {
                    submitRelease(newReleases)
                }

            }
        } else {
            const artistsErrorMessage = missingArtistsMessage.join('<br />')
            handleError(artistsErrorMessage)
        }
    }

    const submitRelease = (newReleases) => {
        update(child(ref(db, 'users'), uid), {
            releases: newReleases
        })
        if (savedRelease !== null) {
            const savedObject = JSON.parse(savedRelease)
            const newDarfts = drafts.filter(item => item.id !== savedObject.id)
            update(child(ref(db, 'users'), uid), {
                drafts: newDarfts
            })
        }
        if (adminsEmails.length > 0) {
            const emailTemplate = emails[5]
            const subject = emailTemplate.subject
            const content = emailTemplate.content.replace(/\*\|user_name\|\*/g, username)
            axios.post('https://bne-backend.vercel.app/submitEmail', {
                email: adminsEmails,
                subject,
                content
            })
            .then(res => console.log(res))
            .catch(err => console.log(err))
        }
        successBtn.current.click()
        localStorage.removeItem('release')
    }



    /////////////// ADD ARTIST ////////////////

    const [name, setName] = useState('')
    const [website, setWebsite] = useState('')
    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [youtube, setYoutube] = useState('')
    const [spotify, setSpotify] = useState('')
    const [apple, setApple] = useState('')
    const [deezer, setDeezer] = useState('')
    const [soundCloud, setSoundCloud] = useState('')
    

    const clearArtistForm = () => {
        setName('');
        setWebsite('');
        setFacebook('');
        setTwitter('');
        setInstagram('');
        setYoutube('');
        setSpotify('');
        setApple('');
        setDeezer('');
        setSoundCloud('');
    }

    const handleAddArtist = () => {

        const artist = {
            name,
            website,
            facebook,
            twitter,
            instagram,
            youtube,
            spotify,
            apple,
            deezer,
            soundCloud
        }
        

        const newArtists = [...artists, artist]
        setArtists(newArtists)
        update(child(ref(db, 'users'), uid), {
            artists: newArtists
        })
        clearArtistForm()
    }


    const fileInputRef = useRef(null);
    const [imgPreview, setImgPreview] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [uploadLabel, setUploadLabel] = useState('Choose file')

    const checkImg = async (event) => {
        setUploadLabel('Please Wait...')
        const file = event.target.files[0];
        const fileType = file.name.split('.').pop().toLowerCase()
        const fileSize = file.size / 1000000
        const reader = new FileReader();


        reader.onload = (e) => {
          const img = new Image();
  
          img.onload = () => {
            const resolution = {
              width: img.width,
              height: img.height,
            };
  
            if (resolution.width === resolution.height && resolution.width <= 3000 && resolution.width >= 1500) {
                uploadArtwork(file)
            } else {
                setUploadLabel('Invalid Resolution')
                setTimeout(() => {
                    setUploadLabel('Choose File...')
                }, 2000);
            }
          };
  
          img.src = e.target.result;
        };


        if (fileSize < 10) {
            if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
                reader.readAsDataURL(file);      
            } else {
                setUploadLabel('Invalid Format')
                setTimeout(() => {
                    setUploadLabel('Choose File...')
                }, 2000);
            }
        } else {
            setUploadLabel('Invalid Size')
            setTimeout(() => {
                setUploadLabel('Choose File...')
            }, 2000);
        }
  
        
    }      

    const uploadArtwork = async (file) => {
        const storageRef = sRef(storage, `releases/${file.name}`);
      
        try {
          // Upload file to Firebase Storage
          await uploadBytes(storageRef, file);
      
          // Get download URL
          const downloadURL = await getDownloadURL(storageRef);
          setImgSrc(downloadURL)
          setImgPreview(true)
          setUploadLabel('Replace file')
        } catch (error) {
          console.error("Error uploading file:", error);
        }
    }      

    const removeImg = () => {
        fileInputRef.current.value = '';
        setImgSrc('')
        setImgPreview(false)
        setUploadLabel('Choose file')
    }

    const [autoArtists, setAutoArtists] = useState(false)
    const [autoGenre, setAutoGenre] = useState(false)
    const [autoLanguage, setAutoLanguage] = useState(false)

    const handleAutoArtists = () => {
        if (autoArtists) {
            setArtists2([])
            setAutoArtists(false)
        } else {
            setArtists2(artists1)
            setAutoArtists(true)
        }
    }

    const handleAutoGenre = () => {
        if (autoGenre) {
            setGenre('')
            setAutoGenre(false)
        } else {
            setGenre(primaryGenre)
            setAutoGenre(true)
        }
    }

    const handleAutoLanguage = () => {
        if (autoLanguage) {
            setTrackLanguage('Instrumental')
            setActiveRadio1(1)
            setAutoLanguage(false)
        } else {
            setTrackLanguage(tracks[editTrackIndex-1].trackLanguage)
            setActiveRadio1(tracks[editTrackIndex-1].trackLanguage === 'Instrumental' ? 1 : 2)
            setAutoLanguage(true)
        }
    }



  return (
    <div className='dash-section'>
        <h1 className='dash-title'>New Release</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-tabs">
                        <Link 
                            to={'#'} 
                            className={`dash-tab ${tab === 1 && 'active'}`}
                            onClick={() => setTab(1)}
                        >Release Info</Link>
                        <Link 
                            to={'#'} 
                            className={`dash-tab ${tab === 2 && 'active'}`}
                            onClick={() => setTab(2)}
                        >Track Info</Link>
                        {/* <Link 
                            to={'#'} 
                            className={`dash-tab ${tab === 3 && 'active'}`}
                            onClick={() => setTab(3)}
                        >Credits</Link> */}
                        <Link 
                            to={'#'} 
                            className={`dash-tab ${tab === 4 && 'active'}`}
                            onClick={() => setTab(4)}
                        >Distribution</Link>
                    </div>
                    {
                        tab === 1 && (
                            <div className="row px-2">
                                <div className="col-12 px-1 mt-3">
                                    <div className="dash-form-group">
                                        <label>Title</label>
                                        <input type="text" placeholder='Release Title' value={title} onChange={(e) => setTitle(e.target.value)} className='dash-form-input' />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-md-5 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Artist Role</label>
                                                <select value={artistRole} onChange={(e) => setArtistRole(e.target.value)} className='dash-form-select'>
                                                    <option>Featuring</option>
                                                    <option>Primary Artist</option>
                                                    <option>Producer</option>
                                                    <option>Arranger</option>
                                                    <option>Composer</option>
                                                    <option>Composer & Lyricist</option>
                                                    <option>Lyricist</option>
                                                    <option>Engineer</option>
                                                    <option>Mixer</option>
                                                    <option>Vocal Producer</option>
                                                    <option>Chorus</option>
                                                    <option>Remixer</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-5 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Artist Name</label>
                                                <div className="dropdown dash-form-dropdown">
                                                    <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {artistName}
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li data-bs-toggle="modal" data-bs-target="#artist1Modal">
                                                            <i className="fi fi-rr-plus"></i>
                                                            Create Artist
                                                        </li>
                                                        {
                                                            artists.map((artist, i) => (
                                                                <li onClick={() => setArtistName(artist.name)}>
                                                                    {artist.name}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 px-1 mt-4">
                                            <div className="dash-form-group">
                                                <div style={{ marginBottom: '20px' }}></div>
                                                <button className={`n-rounded-btn ${artistName === 'Name' && 'disabled'}`} onClick={() => setArtists1([...artists1, {name: artistName, role: artistRole}])}>
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 px-1">
                                            <div className="selected-artists">
                                                {
                                                    artists1.map((artist, i) => (
                                                        <div className='selected-artist'>
                                                            <div className="selected-artist-left">
                                                                <h6>{artist.name}</h6>
                                                                <span>{artist.role}</span>
                                                            </div>
                                                            <i className="fi fi-sr-cross" onClick={() => removeArtist1(i)}></i>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 px-1 mt-3">
                                    <div className="dash-form-group">
                                        <label>Album Artwork</label>
                                        <div className="img-file-upload">
                                            {
                                                imgPreview && (
                                                    <div className='img-preview'>
                                                        <img src={imgSrc} alt="" />
                                                        <i className="fi fi-rr-trash remove-artwork" onClick={removeImg}></i>
                                                    </div>
                                                )
                                            }
                                            <div className="dash-form-file">
                                                <div className="height-equal"></div>
                                                <i className="fi fi-rr-cloud-upload"></i>
                                                {uploadLabel}
                                                <input type="file" ref={fileInputRef} onChange={checkImg} />
                                                <div className="height-equal"></div>
                                            </div>
                                            {
                                                imgPreview === false && (
                                                    <ul className="upload-requirements">
                                                        <li><span>Size:</span> 1500px - 3000px (perfect square)</li>
                                                        <li className='mt-1'><span>Format:</span> JPG or PNG (Max. 10 MB; RGB mode)</li>
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Primary Genre</label>
                                                <div className="dropdown dash-form-dropdown">
                                                    <input 
                                                        type="text" 
                                                        className='dash-form-input' 
                                                        placeholder='Select genre' 
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                        value={primaryGenre}
                                                        onChange={filterPrimaryGenres}
                                                    />
                                                    <ul className="dropdown-menu">
                                                        {
                                                            primaryGenresList.map((item, i) => (
                                                                <li key={i} onClick={() => setPrimaryGenre(item)}>
                                                                    {item}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Secondary Genre</label>
                                                <div className="dropdown dash-form-dropdown">
                                                    <input 
                                                        type="text" 
                                                        className='dash-form-input' 
                                                        placeholder='Select genre' 
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                        value={secondaryGenre}
                                                        onChange={filterSecondaryGenres}
                                                    />
                                                    <ul className="dropdown-menu">
                                                        {
                                                            secondaryGenresList.map((item, i) => (
                                                                <li key={i} onClick={() => setSecondaryGenre(item)}>
                                                                    {item}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Original Release Date</label>
                                                <DatePicker
                                                    id="date-picker"
                                                    selected={originalDate}
                                                    onChange={e => setOriginalDate(e)}
                                                    dateFormat="yyyy/MM/dd"
                                                    locale={enGB} 
                                                    className='dash-form-input'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Digital Release Date</label>
                                                <DatePicker
                                                    id="date-picker"
                                                    selected={digitalDate}
                                                    onChange={e => setDigitalDate(e)}
                                                    dateFormat="yyyy/MM/dd"
                                                    locale={enGB} 
                                                    className='dash-form-input'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 px-1 mt-3">
                                    <div className="dash-form-group">
                                        <label>UPC</label>
                                        <input type="text" placeholder='UPC' value={upc} onChange={(e) => setUpc(e.target.value)} className={`dash-form-input ${upcDisabled && 'disabled'}`} disabled={upcDisabled} />
                                    </div>
                                    <div className="form-check dash-form-check mt-2">
                                        <input className="form-check-input" onChange={() => setUpcDisabled(!upcDisabled)} type="checkbox" id="upc" />
                                        <label className="form-check-label" for="upc">
                                            I don't have one, assign a UPC code to this release automatically
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Copyright</label>
                                                <div className="d-flex">
                                                    <select value={copyrightYear} onChange={(e) => setCopyrightYear(e.target.value)} className='dash-form-select dash-copy-year'>
                                                        {
                                                            years.map(year => (<option key={year}>{year}</option>))
                                                        }
                                                    </select>
                                                    <input type="text" value={copyrightLine} onChange={(e) => setCopyrightLine(e.target.value)} placeholder='Copyright Line' className='dash-form-input dash-copy-line' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Publishing</label>
                                                <div className="d-flex">
                                                    <select value={publishingYear} onChange={(e) => setPublishingYear(e.target.value)} className='dash-form-select dash-copy-year'>
                                                        {
                                                            years.map(year => (<option key={year}>{year}</option>))
                                                        }
                                                    </select>
                                                    <input type="text" value={publishingLine} onChange={(e) => setPublishingLine(e.target.value)} placeholder='Publishing Line' className='dash-form-input dash-copy-line' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Label</label>
                                                <div className="dropdown dash-form-dropdown">
                                                    <input 
                                                        type="text" 
                                                        className='dash-form-input' 
                                                        placeholder='Select label' 
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                        value={label}
                                                        onChange={filterLabels}
                                                    />
                                                    <ul className="dropdown-menu">
                                                        {
                                                            labelsList.map((item, i) => (
                                                                <li key={i} onClick={() => setLabel(item.name)}>
                                                                    {item.name}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 px-1 mt-3">
                                            <div className="dash-form-group">
                                                <label>Territories</label>
                                                <div className="form-check dash-form-check">
                                                    <input className="form-check-input" checked={activeRadio === 1 ? true : false} onChange={() => {setActiveRadio(1); setCheckedCountries(['Worldwide'])}} type="radio" name="territory" id="territory1" />
                                                    <label className="form-check-label" for="territory1">
                                                        Worldwide
                                                    </label>
                                                </div>
                                                <div className="form-check dash-form-check">
                                                    <input className="form-check-input" checked={activeRadio === 2 ? true : false} onChange={() => {setActiveRadio(2); setCheckedCountries([])}} type="radio" name="territory" id="territory2" />
                                                    <label className="form-check-label" for="territory2">
                                                        Select countries
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                activeRadio === 2 && (
                                                    <div className="dash-form-group mt-3">
                                                        <div className="dropdown dash-form-dropdown">
                                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                Countries
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {
                                                                    countries.map((country, i) => (
                                                                        <li className='form-check' key={i}>
                                                                            <input 
                                                                                className="form-check-input" 
                                                                                onChange={(e) => handleCheckCountry(country, e)} 
                                                                                isChecked={checkedCountries.includes(country)}
                                                                                type="checkbox" 
                                                                            />
                                                                            {country}
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 px-1 mt-3">
                                    <div className="dash-form-btns">
                                        <button className='dash-form-btn me-2' onClick={handleSave}>
                                            <i className="fi fi-rr-bookmark me-2"></i>
                                            {saveMsg}
                                        </button>
                                        <button className='dash-form-btn' onClick={() => setTab(2)}>Next</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        tab === 2 && (
                            <div className="row">
                                <div className="col-md-6 mt-3">
                                    <div className="dash-form-group">
                                        <label>Add Track</label>
                                        <div className="dash-form-file py-4">
                                            <i className="fi fi-rr-cloud-upload"></i>
                                            {uploadText}
                                            <input type="file" onChange={readFile} multiple />
                                            <div className="form-file-progress" style={{ width: `${uploadProgress}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                            <div className="added-tracks" {...provided.droppableProps} ref={provided.innerRef}>
                                                {tracks.map((track, i) => (
                                                    <Draggable key={track.id} draggableId={track.id} index={i}>
                                                        {(provided) => (
                                                        <div
                                                            className="added-track"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="added-track-right">
                                                                <i className="fi fi-sr-grip-dots drag-track-btn"></i>
                                                                {i + 1 < 10 ? `0${i + 1}` : i + 1}. {track.title}
                                                            </div>
                                                            <div className="added-track-left">
                                                                <i 
                                                                    className="fi fi-rr-pencil" 
                                                                    data-bs-toggle="modal" 
                                                                    data-bs-target="#editTrackModal"
                                                                    onClick={() => {
                                                                        setEditTitle(track.title)
                                                                        setEditId(track.id)
                                                                        setArtists2(track.artists || [])
                                                                        setGenre(track.genre)
                                                                        setIsrc(track.isrc)
                                                                        setCopyright(track.copyright)
                                                                        setTrackLanguage(track.trackLanguage)
                                                                        setClipStart(track.clipStart)
                                                                        setAutoGenre(false)
                                                                        setAutoArtists(false)
                                                                        setEditTrackIndex(i)
                                                                        track.trackLanguage !== 'Instrumental' ? setActiveRadio1(2) : setActiveRadio1(1)
                                                                    }}
                                                                ></i>
                                                                <i 
                                                                    className="fi fi-sr-cross"
                                                                    onClick={() => removeTrack(i)}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                <div className="col-12 mt-4">
                                    <div className="dash-form-btns">
                                        <button className='dash-form-btn me-2' onClick={handleSave}>
                                            <i className="fi fi-rr-bookmark me-2"></i>
                                            {saveMsg}
                                        </button>
                                        <button className='dash-form-btn' onClick={() => setTab(4)}>Next</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {/* {
                        tab === 3 && (
                            <div className="row">
                                <div className="col-md-6 mt-3">
                                    <div className="dash-form-group">
                                        <label>Role</label>
                                        <select value={role} onChange={(e) => setRole(e.target.value)} className='dash-form-select'>
                                            <option>Arranger</option>
                                            <option>Composer</option>
                                            <option>Lyricist</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="dash-form-group">
                                        <label>Writer Name</label>
                                        <input 
                                            type="text" 
                                            placeholder='Writer Name' 
                                            className='dash-form-input' 
                                            value={writerName}
                                            onChange={(e) => setWriterName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="dash-form-group">
                                        <label>Share (%)</label>
                                        <input 
                                            type="number" 
                                            placeholder='Share (%)' 
                                            className='dash-form-input' 
                                            value={share}
                                            onChange={(e) => setShare(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="dash-form-btns">
                                        <button className='dash-form-btn' onClick={() => setTab(4)}>Next</button>
                                    </div>
                                </div>
                            </div>
                        )
                    } */}
                    {
                        tab === 4 && (
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <div className="table-responsive">
                                        <table className="table dash-table">
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className='dash-form-check'>
                                                                <input 
                                                                    type="checkbox" 
                                                                    className='form-check-input me-3' 
                                                                    onChange={checkAll}
                                                                />
                                                            </div>
                                                            Platform
                                                        </div>
                                                    </td>
                                                    <td>Description</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    platforms.map((platform, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <div className="table-item d-flex align-items-center">
                                                                    <div className='dash-form-check'>
                                                                        <input 
                                                                            type="checkbox" 
                                                                            className='form-check-input' 
                                                                            checked={platform.checked}
                                                                            onChange={() => handleCheck(i)}
                                                                        />
                                                                    </div>
                                                                    <img className='platform-img' src={platform.logo} alt="" />
                                                                    <h6 className='ms-2'>{platform.title}</h6>
                                                                </div>
                                                            </td>
                                                            <td><div className='table-item'>{platform.description}</div></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    error !== '' && (
                                        <div className="col-12 mt-3">
                                            <h6 className='text-danger text-center' dangerouslySetInnerHTML={{ __html: error }}></h6>
                                        </div>
                                    )
                                }
                                <div className="col-12 mt-3">
                                    <div className="dash-form-btns">
                                        <button className='dash-form-btn' onClick={handleAddRelease}>Submit</button>
                                        <button ref={successBtn} data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>


        {/* ARTIST MODAL */}
        <div className="modal fade" id="artist1Modal" tabindex="-1" aria-labelledby="artist1ModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="artist1ModalLabel">Add Artist</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <h4 className='dash-form-heading'>General Information</h4>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Name</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Website</label>
                                    <input value={website} onChange={(e) => setWebsite(e.target.value)} type="text" placeholder='Website' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <h4 className='dash-form-heading'>Profile Information</h4>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Spotify</label>
                                    <input value={spotify} onChange={(e) => setSpotify(e.target.value)} type="text" placeholder='Spotify' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Apple Music</label>
                                    <input value={apple} onChange={(e) => setApple(e.target.value)} type="text" placeholder='Apple' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Facebook</label>
                                    <input value={facebook} onChange={(e) => setFacebook(e.target.value)} type="text" placeholder='Facebook' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Instagram</label>
                                    <input value={instagram} onChange={(e) => setInstagram(e.target.value)} type="text" placeholder='Instagram' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Youtube</label>
                                    <input value={youtube} onChange={(e) => setYoutube(e.target.value)} type="text" placeholder='Youtube' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>X</label>
                                    <input value={twitter} onChange={(e) => setTwitter(e.target.value)} type="text" placeholder='Twitter' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Deezer</label>
                                    <input value={deezer} onChange={(e) => setDeezer(e.target.value)} type="text" placeholder='Deezer' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>SoundCloud</label>
                                    <input value={soundCloud} onChange={(e) => setSoundCloud(e.target.value)} type="text" placeholder='SoundCloud' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            handleAddArtist()
                                            setArtistName(name)
                                        }}
                                    >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="artist2Modal" tabindex="-1" aria-labelledby="artist2ModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="artist2ModalLabel">Add Artist</h1>
                        <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#editTrackModal"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <h4 className='dash-form-heading'>General Information</h4>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Name</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Website</label>
                                    <input value={website} onChange={(e) => setWebsite(e.target.value)} type="text" placeholder='Website' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <h4 className='dash-form-heading'>Profile Information</h4>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Spotify</label>
                                    <input value={spotify} onChange={(e) => setSpotify(e.target.value)} type="text" placeholder='Spotify' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Apple Music</label>
                                    <input value={apple} onChange={(e) => setApple(e.target.value)} type="text" placeholder='Apple' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Facebook</label>
                                    <input value={facebook} onChange={(e) => setFacebook(e.target.value)} type="text" placeholder='Facebook' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Instagram</label>
                                    <input value={instagram} onChange={(e) => setInstagram(e.target.value)} type="text" placeholder='Instagram' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Youtube</label>
                                    <input value={youtube} onChange={(e) => setYoutube(e.target.value)} type="text" placeholder='Youtube' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>X</label>
                                    <input value={twitter} onChange={(e) => setTwitter(e.target.value)} type="text" placeholder='Twitter' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Deezer</label>
                                    <input value={deezer} onChange={(e) => setDeezer(e.target.value)} type="text" placeholder='Deezer' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>SoundCloud</label>
                                    <input value={soundCloud} onChange={(e) => setSoundCloud(e.target.value)} type="text" placeholder='SoundCloud' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        data-bs-toggle="modal" 
                                        data-bs-target="#editTrackModal"
                                        onClick={() => {
                                            handleAddArtist()
                                            setArtists2([...artists2, {name: name, role: artistRole}])
                                        }}
                                    >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="modal fade" id="editTrackModal" tabindex="-1" aria-labelledby="editTrackModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editTrackModalLabel">Edit Track</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Title</label>
                                    <input type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} placeholder='Title' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-5 mt-3">
                                <div className="dash-form-group">
                                    <label>Artist Role</label>
                                    <select value={artistRole} onChange={(e) => setArtistRole(e.target.value)} className='dash-form-select'>
                                        <option>Featuring</option>
                                        <option>Primary Artist</option>
                                        <option>Producer</option>
                                        <option>Arranger</option>
                                        <option>Composer</option>
                                        <option>Composer & Lyricist</option>
                                        <option>Lyricist</option>
                                        <option>Engineer</option>
                                        <option>Mixer</option>
                                        <option>Vocal Producer</option>
                                        <option>Chorus</option>
                                        <option>Remixer</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-5 mt-3">
                                <div className="dash-form-group">
                                    <label>Artist Name</label>
                                    <div className="dropdown dash-form-dropdown">
                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {artistName}
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li data-bs-toggle="modal" data-bs-target="#artist1Modal">
                                                <i className="fi fi-rr-plus"></i>
                                                Create Artist
                                            </li>
                                            {
                                                artists.map((artist, i) => (
                                                    <li onClick={() => setArtistName(artist.name)}>
                                                        {artist.name}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mt-4">
                                <div className="dash-form-group">
                                    <div style={{ marginBottom: '20px' }}></div>
                                    <button className={`n-rounded-btn ${artistName === 'Name' && 'disabled'}`} onClick={() => setArtists2([...artists2, {name: artistName, role: artistRole}])}>
                                        <i className="fi fi-rr-plus"></i>
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-check dash-form-check mt-2">
                                    <input className="form-check-input" type="checkbox" checked={autoArtists} onChange={handleAutoArtists} id="autoArtists" />
                                    <label className="form-check-label" htmlFor="autoArtists">
                                        Add Artists from Release
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="selected-artists">
                                    {
                                        artists2.map((artist, i) => (
                                            <div className='selected-artist'>
                                                <div className="selected-artist-left">
                                                    <h6>{artist.name}</h6>
                                                    <span>{artist.role}</span>
                                                </div>
                                                <i className="fi fi-sr-cross" onClick={() => removeArtist2(i)}></i>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Genre</label>
                                    <div className="dropdown dash-form-dropdown">
                                        <input 
                                            type="text" 
                                            className='dash-form-input' 
                                            placeholder='Select genre' 
                                            data-bs-toggle="dropdown" 
                                            aria-expanded="false"
                                            value={genre}
                                            onChange={filterGenres}
                                        />
                                        <ul className="dropdown-menu">
                                            {
                                                genresList.map((item, i) => (
                                                    <li key={i} onClick={() => setGenre(item)}>
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-check dash-form-check mt-2">
                                    <input className="form-check-input" type="checkbox" checked={autoGenre} onChange={handleAutoGenre} id="autoGenre" />
                                    <label className="form-check-label" htmlFor="autoGenre">
                                        Use Genre from Release
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Preview/Clip Start Time</label>
                                    <input type="text" value={clipStart} onChange={(e) => setClipStart(e.target.value)} placeholder='mm:ss' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>ISRC</label>
                                    <input 
                                        type="text" 
                                        placeholder='LL-LLL-NN-NNNNN' 
                                        className={`dash-form-input ${isrcDisabled && 'disabled'}`} 
                                        disabled={isrcDisabled} 
                                        value={isrc}
                                        onChange={(e) => setIsrc(e.target.value)}
                                    />
                                </div>
                                <div className="form-check dash-form-check mt-2">
                                    <input className="form-check-input" type="checkbox" checked={isrcDisabled} onChange={() => setIsrcDisabled(!isrcDisabled)} id="isrc" />
                                    <label className="form-check-label" for="isrc">
                                        I don't have one, assign a ISRC code to this release automatically
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Copyright</label>
                                    <select value={copyright} onChange={(e) => setCopyright(e.target.value)} className='dash-form-select'>
                                        <option>Original Song</option>
                                        <option>Public Domain</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Language of Lyrics</label>
                                    <div className="form-check dash-form-check">
                                        <input 
                                            className="form-check-input" 
                                            checked={activeRadio1 === 1 ? true : false} 
                                            onChange={() => {setActiveRadio1(1); setTrackLanguage('Instrumental')}} 
                                            type="radio" 
                                            name="trackRadios" 
                                            id="tr1" 
                                        />
                                        <label className="form-check-label" for="tr1">
                                            Instrumental
                                        </label>
                                    </div>
                                    <div className="form-check dash-form-check">
                                        <input 
                                            className="form-check-input" 
                                            checked={activeRadio1 === 2 ? true : false} 
                                            onChange={() => {setActiveRadio1(2); setTrackLanguage(languages[0])}} 
                                            type="radio" 
                                            name="trackRadios" 
                                            id="tr2" 
                                        />
                                        <label className="form-check-label" for="tr2">
                                            Select Language
                                        </label>
                                    </div>
                                </div>
                                {
                                    activeRadio1 === 2 && (
                                        <div className="dash-form-group mt-3">
                                            <select value={trackLanguage} onChange={(e) => setTrackLanguage(e.target.value)} className='dash-form-select'>
                                                <option>{languages[0]}</option>
                                                {
                                                    languages.map((language, i) => (
                                                        <option key={i}>{language}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    )
                                }
                                {
                                    editTrackIndex > 0 && (
                                        <div className="col-12">
                                            <div className="form-check dash-form-check mt-2">
                                                <input className="form-check-input" type="checkbox" checked={autoLanguage} onChange={handleAutoLanguage} id="autoLanguage" />
                                                <label className="form-check-label" htmlFor="autoLanguage">
                                                    Copy From Previous Track
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Explicit</label>
                                    <div className="form-check dash-form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="editEx" 
                                            id="editEx1"
                                            checked={explicit === 'Yes'}
                                            onChange={() => setExplicit('Yes')} 
                                        />
                                        <label className="form-check-label" for="editEx1">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check dash-form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="radio" 
                                            name="editEx"
                                            id="editEx2" 
                                            checked={explicit === 'No'}
                                            onChange={() => setExplicit('No')}
                                        />
                                        <label className="form-check-label" for="editEx2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-group">
                                    <label>Lyrics</label>
                                    <textarea value={lyrics} onChange={e => setLyrics(e.target.value)} className='dash-form-textarea'></textarea>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        data-bs-dismiss="modal"
                                        onClick={updateTrack}
                                    >Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/releases">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Release Uploaded Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>


        <button data-bs-toggle="modal" data-bs-target="#draftSuccess" ref={draftSuccess} hidden></button>
        <div className="modal fade" id="draftSuccess" tabindex="-1" data-bs-backdrop="static" aria-labelledby="draftSuccessLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/releases">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Draft Saved Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

        <button data-bs-toggle="modal" data-bs-target="#warningPopup" ref={warningPopupBtn} hidden></button>
        <div className="modal fade" id="warningPopup" tabindex="-1" data-bs-backdrop="static" aria-labelledby="warningPopupLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <Link className='success-close-btn' to="#" data-bs-dismiss="modal">
                            <i className="fi fi-rr-cross"></i>
                        </Link>
                        <h5>4 or more primary artists would replace the artist name to "Various Artists" on the release</h5>
                        <div className="d-flex justify-content-center mt-4">
                            <button className="dash-card-heading-btn cancel-btn share-btn me-2" data-bs-dismiss="modal">Cancel</button>
                            <button 
                                className={`dash-card-heading-btn share-btn`} 
                                onClick={() => submitRelease(newReleasesItems)}
                            >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={`loader-container ${loader && 'active'}`}>
            <div className="loader"></div>
        </div>
    </div>
  )
}

export default AddRelease